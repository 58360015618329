import { render, staticRenderFns } from "./admin-balances.vue?vue&type=template&id=4ef1852f&"
import script from "./admin-balances.vue?vue&type=script&lang=js&"
export * from "./admin-balances.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/frank/Documents/RacknerProjects/Bobtail/bobtail_reset_6-3/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ef1852f')) {
      api.createRecord('4ef1852f', component.options)
    } else {
      api.reload('4ef1852f', component.options)
    }
    module.hot.accept("./admin-balances.vue?vue&type=template&id=4ef1852f&", function () {
      api.rerender('4ef1852f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/admin-balances.vue"
export default component.exports