var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "AdminBalance" }, [
    _c(
      "div",
      { staticClass: "row row--align-center row--justify-between mb-17" },
      [
        _vm._m(0),
        _c(
          "base-button",
          {
            staticClass: "bg-blue fc-white",
            on: { click: _vm.fixDiscrepancies }
          },
          [_vm._v("\n      Fix Discrepancies\n    ")]
        )
      ],
      1
    ),
    _vm.discrepancies.length
      ? _c(
          "ul",
          _vm._l(_vm.discrepancies, function(client) {
            return _c(
              "li",
              {
                key: client,
                attrs: {
                  id: "Testing__ClientDiscrepency-" + _vm.index,
                  tabindex: "0"
                }
              },
              [
                _vm._v("\n      Client id: "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/client/" +
                        client.allOffBalances[0].client_id +
                        "/activity",
                      target: "_blank"
                    }
                  },
                  [_vm._v(_vm._s(client.allOffBalances[0].client_id))]
                ),
                _c("br"),
                _c(
                  "ul",
                  _vm._l(client.allOffBalances, function(balance) {
                    return _c(
                      "li",
                      {
                        key: balance.id,
                        attrs: {
                          id: "Testing__BalanceDiscrepancy-" + balance.id,
                          tabindex: "0"
                        }
                      },
                      [
                        _vm._v("\n          Balance id: " + _vm._s(balance.id)),
                        _c("br"),
                        _vm._v(
                          "\n          Description: Type: " +
                            _vm._s(balance.type) +
                            ", Reason: " +
                            _vm._s(balance.reason) +
                            ", Notes: " +
                            _vm._s(balance.notes)
                        ),
                        _c("br"),
                        _vm._v(
                          "\n          Date: " +
                            _vm._s(
                              _vm._f("datetime")(
                                balance.created_at,
                                "MM/DD/YYYY, hh:mm:ss"
                              )
                            )
                        ),
                        _c("br"),
                        _vm._v(
                          "\n          Amount: " +
                            _vm._s(balance.nacha_sign) +
                            "$" +
                            _vm._s(balance.amount)
                        ),
                        _c("br"),
                        _vm._v("\n          Total: $" + _vm._s(balance.total)),
                        _c("br"),
                        _vm._v(
                          "\n          Expected Total: $" +
                            _vm._s(balance.expectedTotal)
                        ),
                        _c("br")
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          }),
          0
        )
      : _c("div", [
          _c("label", { staticClass: "fs-16" }, [
            _vm._v("There are no balance discrepancies")
          ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column column--width-auto" }, [
      _c("label", { staticClass: "fs-18 fw-med" }, [_vm._v("Balance Admin")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }