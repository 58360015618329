<template>
  <div class="AdminBalance">
    <div class="row row--align-center row--justify-between mb-17">
      <div class="column column--width-auto">
        <label class="fs-18 fw-med">Balance Admin</label>
      </div>
      <base-button
        @click="fixDiscrepancies"
        class="bg-blue fc-white"
      >
        Fix Discrepancies
      </base-button>
    </div>
    <ul v-if="discrepancies.length">
      <li
        v-for="client in discrepancies"
        :key="client"
        :id="`Testing__ClientDiscrepency-${index}`"

        tabindex="0"
      >
        <!-- {{client.id}}<br> -->
        Client id: <a
          :href="`/client/${client.allOffBalances[0].client_id}/activity`"
          target="_blank"
        >{{ client.allOffBalances[0].client_id }}</a><br>
        <ul>
          <li
            v-for="balance in client.allOffBalances"
            :key="balance.id"
            :id="`Testing__BalanceDiscrepancy-${balance.id}`"
            tabindex="0"
          >
            Balance id: {{ balance.id }}<br>
            Description: Type: {{ balance.type }}, Reason: {{ balance.reason }}, Notes: {{ balance.notes }}<br>
            Date: {{ balance.created_at | datetime('MM/DD/YYYY, hh:mm:ss') }}<br>
            Amount: {{ balance.nacha_sign }}${{ balance.amount }}<br>
            Total: ${{ balance.total }}<br>
            Expected Total: ${{ balance.expectedTotal }}<br>
          </li>
        </ul>
      </li>
    </ul>
    <div v-else>
      <label class="fs-16">There are no balance discrepancies</label>
    </div>
  </div>
</template>

<script>
// Packages
// Helpers
import { Balance } from '../../utils/api'
// Components
import BaseButton from '../../components/base-button.vue'
// import BaseDatePicker from '../../components/base-date-picker.vue'
// Mixins
import {
  ValidationMixin
} from '../../utils/validation-mixin'

export default {
  name: 'AdminBalance',

  mixins: [ValidationMixin],

  components: {
    BaseButton,
    // TODO: V2
    // BaseDatePicker,
  },
  async created () {
    this.listDiscrepancies();
  },
  data () {
    return {
      discrepancies: [],
      total: 0
    }
  },

  methods: {
    async fixDiscrepancies() {
      await Balance.fixDiscrepancies();
      this.listDiscrepancies();
    },
    async listDiscrepancies() {
      const results = (await Balance.getDiscrepancies()).data;
      this.total = [...results.errors];
      delete results.errors
      this.discrepancies = Object.keys(results).map(key => {
        results[key].client = key;
        return results[key]
      });
    },
  }
}
</script>

<style lang="sass">

</style>